import React, { useState, useEffect } from "react";
import "./VehicleFlow.css";
import TripDataService from "../../services/TripData";
import StaticDataService from "../../services/staticData";
// import { globalVariable } from "../../globals";
import { Link } from "react-router-dom";
import FlowWidget from "./CommanComp/FlowWidget";
import Select from "react-select";
import Modal from 'react-modal';
import Lottie from "lottie-react";
import animationData from "../../animation-1.json";

const VehicleFlow = () => {
  //{"cruise_id":54,"sailing_date":"11/15/2023","sailing_time":"07:30:00","sailing_type":"1_way","sailing_way":"onward","channel_phone":"","session_id":"80447879"}
  const [formData, setFormData] = useState(null);
  const [onwardCycle, setOnwardCycle] = useState([]);
  const [onwardBike, setOnwardBike] = useState([]);
  const [error, setError] = useState([]);
  const [brandSelected, setBrandSelected] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedModelVal, setSelectedModelVal] = useState([]);
  
  const [selectedReturnValues, setSelectedReturnValues] = useState({});
  const [selectedReturnModelVal, setSelectedReturnModelVal] = useState([]);
  const [returnCycle, setReturnCycle] = useState([]);
  const [returnBike, setReturnBike] = useState([]);
  const [model_options, setModel_options] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const nameMap = { MUM: "Mumbai", MWA: "Mandwa" };

  const [triptype, setTriptype] = useState(null);
  const selectedVehicle = [];
  const [carBrands, setCarBrands] = useState([]);

  const [carData, setCarData] = useState([]);
  const [carDataReturn, setCarDataReturn] = useState([]);
  /* const { globalArray, addToGlobalArray, removeFromGlobalArray } = useGlobalState(); */

  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const storedData = JSON.parse(localStorage.getItem("formData"));
    // console.log(storedData);
    if (storedData) {
      setFormData(storedData);
    }
    console.log(storedData.onwardJourney.SelTripData.cruise_id)
    const DateCapture = storedData.onwardJourney.selectedDateNew1;
    const DateCaptureTime = storedData.onwardJourney.SelTripData.sailing_time;

    var sailing_date = DateCapture;
    var sailing_time = DateCaptureTime;

    var rawData = JSON.stringify({
      cruise_id: storedData.onwardJourney.SelTripData.cruise_id,
      sailing_date: sailing_date,
      sailing_time: sailing_time,
      sailing_type: "1_way",
      sailing_way: "onward",
      channel_phone: "",
      session_id: "80447879",
      /* route_filter: "MUM-MWA",
      live_data: true, */
    });
    
    var rawDataReturn = "";
    if (storedData.triptype === "return") {
      setTriptype(storedData.triptype);
      const DateCaptureReturn = storedData.returnJourney.selecteRetdDateNew1;
      const DateCaptureTimeReturn =
        storedData.returnJourney.SelTripData.sailing_time;

      var return_sailing_date = DateCaptureReturn;
      var return_sailing_time = DateCaptureTimeReturn;
      rawDataReturn = JSON.stringify({
        cruise_id: storedData.returnJourney.SelTripData.cruise_id,
        sailing_date: return_sailing_date,
        sailing_time: return_sailing_time,
        sailing_type: "custom",
        sailing_way: "return",
        channel_phone: "",
        session_id: "80447879",
        /* route_filter: "MUM-MWA",
        live_data: true, */
      });
    }
    filterCarBrands(storedData, rawData, rawDataReturn, storedData.triptype);

  }, []);

  const filterCarBrands = async (storedData, rawData, rawDataReturn, tripType) => {
    console.log("rawData", rawData);
    const result = [];
    const resultReturn = [];
    let cycle;
    let motorcycle;
    try {
      const [carBrandsArr, excursionDetails] = await Promise.all([
        StaticDataService.carBrandsData(),
        TripDataService.fetchVehicles(rawData)
      ]);
      const carBrands = carBrandsArr.sort((a, b) => a.label.localeCompare(b.label));
      setCarBrands(carBrands);
      const carBrandNames = carBrands.map(brand => brand.value);
      const brands = carBrandNames.map(name => ({ name }));
      const models = carBrandNames.reduce((acc, brand) => {
        acc[brand] = [];
        return acc;
      }, {});


      excursionDetails.onshore_excursion_details.forEach(excursion => {
        carBrandNames.forEach(brand => {
          if (excursion.excursion_name.includes(brand)) {
            models[brand].push(excursion);
          }
        });
      });
      Object.keys(models).forEach(brand => {
        models[brand].sort((a, b) => a.excursion_name.localeCompare(b.excursion_name));
      });

      excursionDetails.onshore_excursion_details.map((item) => {
        const type = item.excursion_type_name;
        const namearray = item.excursion_name.split(" ");
        
        const itemprice = Math.round(item.price);
        item.price = itemprice;
        const vehicleObj = item;
        if (type == "Pet") {
          //this.dashboardService.setPetObject(item);
        } else if (type == "Two-wheeler") {
          if (namearray == "Bicycle") {
            cycle = item;
          } else {
            motorcycle = item;
          }
        }
      });
      setOnwardCycle(cycle);
      setOnwardBike(motorcycle);
      
      /* const models = Object.keys(modelsMap).map(brand => ({
        [brand]: modelsMap[brand]
      })); */

      [...Array(storedData.onwardJourney.vehicleData.Carcount)].map((e, i) => {
        var obj1 = {};
        obj1 = {
          id: i,
          brands: brands,
          models: models,
        };
        result.push(obj1);
      });
      //console.log("result L: ", result);
      //result.push({brands, models});
      setCarData(result);

      if (tripType === "return") {

        const [carBrandsReturn, excursionDetailsreturn] = await Promise.all([
          //StaticDataService.carBrandsData(),
          carBrands,
          TripDataService.fetchVehicles(rawDataReturn)
        ]);
        //console.log('excursionDetailsreturn : ',excursionDetailsreturn);
        const carBrandNamesReturn = carBrandsReturn.map(brand => brand.value);
        const brandsReturn = carBrandNamesReturn.map(name => ({ name }));
        const modelsReturn = carBrandNamesReturn.reduce((acc, brand) => {
          acc[brand] = [];
          return acc;
        }, {});

        excursionDetailsreturn.onshore_excursion_details.forEach(excursion => {
          carBrandNamesReturn.forEach(brand => {
            if (excursion.excursion_name.includes(brand)) {
              modelsReturn[brand].push(excursion);
            }
          });
        });
        Object.keys(modelsReturn).forEach(brand => {
          modelsReturn[brand].sort((a, b) => a.excursion_name.localeCompare(b.excursion_name));
        });
        //console.log("modelsReturn : ",modelsReturn);
        /* const modelsReturn = Object.keys(modelsMap).map(brand => ({
          [brand]: modelsMap[brand]
        })); */


        excursionDetailsreturn.onshore_excursion_details.map((item) => {
          const type = item.excursion_type_name;
          const namearray = item.excursion_name.split(" ");
          const itemprice = Math.round(item.price);
          item.price = itemprice;
          const vehicleObj = item;
          if (type == "Pet") {
            //this.dashboardService.setPetObject(item);
          } else if (type == "Two-wheeler") {
            if (namearray == "Bicycle") {
              cycle = item;
            } else {
              motorcycle = item;
            }
          }
        });
        setReturnCycle(cycle);
        setReturnBike(motorcycle);

        //resultReturn.push({brands: brandsReturn, models: modelsReturn});
        [...Array(storedData.onwardJourney.vehicleData.Carcount)].map((e, i) => {
          var obj1 = {};
          obj1 = {
            id: i,
            brands: brandsReturn,
            models: modelsReturn,
          };
          resultReturn.push(obj1);
        });
        setCarDataReturn(resultReturn);

      }
    } catch (err) {
      setError(err.message);
    }
  };

  const objArr = [];
  const handleCategoryChange = (setId, newvalue) => {
    //console.log(setId, newvalue);
    const newSelectedCategories = {
      ...selectedCategories,
      [setId]: newvalue,
    };
    setSelectedCategories(newSelectedCategories);
    //setBrandSelected(true);
    // Reset selected value when the category changes
    const newSelectedValues = { ...selectedValues, [setId]: "" };
    const newSelectedModel = { ...selectedModelVal, [setId]: "" };
    
    setSelectedValues(newSelectedValues);
    setSelectedModelVal(newSelectedModel);

    if (formData.triptype === "return") {
      // Return Journey
      const newReturnSelectedValues = { ...selectedReturnValues, [setId]: "" };
      const newReturnSelectedModel = { ...selectedReturnModelVal, [setId]: "" };
      setSelectedReturnValues(newReturnSelectedValues);
      setSelectedReturnModelVal(newReturnSelectedModel);
    }
    
    carData.map((dataSet, index) => {
      if (brandSelected == false) {
        if(dataSet.id == 0){
          dataSet.models[newSelectedCategories[setId]].map(
            (value, modelindex) => {
              var obj = {};
              var excursion_name = value.excursion_name;
              var selected_brand = newSelectedCategories[setId];
              const newModelValue = excursion_name.replace(selected_brand, '');
              obj["value"] = value.excursion_id;
              obj["label"] = newModelValue;
              objArr.push(obj);
            }
          );
        }
      }
    });
    //console.log(objArr);
    //model_options1.push({ [setId] :  objArr});
    const newSelectedModelsdr = {
      ...model_options,
      [setId]: objArr,
    };
    setModel_options(newSelectedModelsdr);
  };

  const handleValueChange = (setId, newvalue, dataSet) => {
    //console.log(selectedVehicle);
    
    var dataSetModel = dataSet.models[selectedCategories[dataSet.id]];
    var selectedModel = dataSetModel.find(
      (select) => select.excursion_id == newvalue
    );
    const newSelectedValues = {
      ...selectedValues,
      [setId]: newvalue,
    };
    const newSelectedModel = { ...selectedModelVal, [setId]: selectedModel };

    setSelectedValues(newSelectedValues);
    setSelectedModelVal(newSelectedModel);

    if (formData.triptype === "return") {

      var dataSetReturnModel = carDataReturn[setId].models[selectedCategories[dataSet.id]];
      var selectedReturnModel = dataSetReturnModel.find(
        (select) => select.excursion_id == newvalue
      );
      const newReturnSelectedValues = {
        ...selectedReturnValues,
        [setId]: newvalue,
      };
      const newReturnSelectedModel = {
        ...selectedReturnModelVal,
        [setId]: selectedReturnModel,
      };
      setSelectedReturnValues(newReturnSelectedValues);
      setSelectedReturnModelVal(newReturnSelectedModel);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isBrowserAlerts, setIsBrowserAlerts] = useState();
  
  const closeModal = () => {
    setIsOpen(false);
    setIsBrowserAlerts('');
  };

  const handleButtonClick = () => {
    if (formData) {
      setLoading(true);
      var selectedCarCnt = 0;
      const onwardVehicleResult = Object.values(selectedModelVal);
      onwardVehicleResult.map((value) =>{
        if(value) {
          selectedCarCnt++;
        }
      });
      //console.log(selectedCarCnt);
      if(selectedCarCnt < formData.onwardJourney.vehicleData.Carcount){
        setIsBrowserAlerts("Please select car model.");
        return false;
      }
      if (formData.onwardJourney.vehicleData.Scootercount > 0) {
        for ( var i = 0; i < formData.onwardJourney.vehicleData.Scootercount; i++ ) {
          onwardVehicleResult.push(onwardBike);
        }
      }
      if (formData.onwardJourney.vehicleData.Cyclecount > 0) {
        for ( var i = 0; i < formData.onwardJourney.vehicleData.Cyclecount; i++) {
          onwardVehicleResult.push(onwardCycle);
        }
      }
      const vehicleQuantity = {};

      onwardVehicleResult.filter((item) => {
        if (vehicleQuantity[item.excursion_id]) {
          vehicleQuantity[item.excursion_id]++;
        } else {
          vehicleQuantity[item.excursion_id] = 1;
        }
      });
      const carsOnward = [
        ...new Set(onwardVehicleResult.map((a) => JSON.stringify(a))),
      ].map((a) => JSON.parse(a));


      carsOnward.filter((item) => {
        item.quantity = vehicleQuantity[item.excursion_id];
      });

      if (formData.triptype === "return") {
        const returnVehicleResult = Object.values(selectedReturnModelVal);

        if (formData.returnJourney.vehicleData.Scootercount > 0) {
          for ( var i = 0; i < formData.returnJourney.vehicleData.Scootercount; i++ ) {
            returnVehicleResult.push(returnBike);
          }
        }
        if (formData.returnJourney.vehicleData.Cyclecount > 0) {
          for ( var i = 0; i < formData.returnJourney.vehicleData.Cyclecount; i++ ) {
            returnVehicleResult.push(returnCycle);
          }
        }

        const vehicleQuantityReturn = {};

        returnVehicleResult.filter((item) => {
          if (vehicleQuantityReturn[item.excursion_id]) {
            vehicleQuantityReturn[item.excursion_id]++;
          } else {
            vehicleQuantityReturn[item.excursion_id] = 1;
          }
        });
        const carsReturn = [
          ...new Set(returnVehicleResult.map((a) => JSON.stringify(a))),
        ].map((a) => JSON.parse(a));
        carsReturn.filter((item) => {
          item.quantity = vehicleQuantityReturn[item.excursion_id];
        });
        
        formData.onwardJourney.SelTripData.selectedVehicles = carsOnward;
        formData.returnJourney.SelTripData.selectedVehicles = carsReturn;
        localStorage.setItem("formData", JSON.stringify(formData));
      } else {
        formData.onwardJourney.SelTripData.selectedVehicles = carsOnward;
        localStorage.setItem("formData", JSON.stringify(formData));
      }
      window.location = "/confirmation";
    }
  };
  
  const brand_options = carBrands;
  console.log("carData : ", carData);
  return (
    <>
        <div>
          {/* breadcum code start */}
          <div className="Breadcum_Line_veh">
            <div className="Breadcum_Flow_veh">
              <Link to={"/"} className="link-without-underline">
                <div className="Breadcum_Flow_Home_veh" id="homeAbc_veh">
                  <h1>M2M</h1>
                  <p> Home</p>
                </div>
              </Link>{" "}
              <div className="Breadcum_Flow_Logo_veh">
                <p>&#62;</p>
              </div>
              <div className="Breadcum_Flow_Home_veh" id="Departure_vechicle">
                <Link
                  to={"/onward-departure"}
                  className="link-without-underline"
                >
                  <h1>Departure</h1>
                </Link>
                {formData && formData.onwardJourney && (
                  <p>
                    {nameMap[formData.onwardJourney.selectedFromAbbreviation]}{" "}
                    To {nameMap[formData.onwardJourney.selectedToAbbreviation]}
                  </p>
                )}
              </div>
              {formData && formData.triptype === "return" && (
                <>
                  <div className="Breadcum_Flow_Logo_veh">
                    <p>&#62;</p>
                  </div>
                  <div className="Breadcum_Flow_Home_veh" id="return_vechicle">
                    <Link
                      to={"/onward-return"}
                      className="link-without-underline"
                    >
                      <h1>Return</h1>
                    </Link>
                    {/* <p>Mandwa To Mumbai</p> */}
                    <p>
                      {formData.returnJourney &&
                        nameMap[
                          formData.returnJourney.selectedToAbbreviation
                        ]}{" "}
                      To{" "}
                      {formData.returnJourney &&
                        nameMap[
                          formData.returnJourney.selectedFromAbbreviation
                        ]}
                    </p>
                  </div>
                </>
              )}
              <div className="Breadcum_Flow_Logo_veh">
                <p>&#62;</p>
              </div>
              <div className="Breadcum_Flow_Departure_veh">
                <h1>Vehicle</h1>
                <p>Choose Vehicle</p>
              </div>
            </div>
          </div>
          {/* breadcum code end */}
          {loading && !carData ? (
            <Lottie
              animationData={animationData}
              style={{ width: "200px", height: "200px", margin: "auto" }}
            />
          ) : (
            <div className="Vehicle_container">
              {carData.map((dataSet, index) => (
                <div className={`Vehicle_Row Vehicle_Row_${index}`} key={index}>
                  <div className="Vehicle_img_info">
                    <div className="Vehicle_images">
                      <img src="/assets/img/service/car.png" alt="4 wheeler" />
                    </div>
                    <div className="Vehicle_infos">
                      <div>
                        <h1>4 Wheelers</h1>
                      </div>
                      <div>
                        <p>CAR {index + 1}</p>
                      </div>
                    </div>
                  </div>
                  <div className="Vehicle_tags">
                    <div className="Vehicle_tags_1">
                      <div className="Vechicle_label">
                        <p>Car Brand</p>
                      </div>
                      <div className="Vechicle_custom_select">
                        <div className={`Vechicle_select_header`}>
                          <div className="Vechicle_select_button">
                            {/* <select
                              id={`car-brand-${dataSet.id}`}
                              onChange={(event) =>
                                handleCategoryChange(dataSet.id, event)
                              }
                              value={selectedCategories[dataSet.id] || ""}
                            >
                              <option value="">Select Option</option>

                              {dataSet.brands.map((category, catindex) => (
                                <option key={catindex} value={category.name}>
                                  {category.name}
                                </option>
                              ))}
                            </select> */}
                            <Select
                              id={`car-brand-${dataSet.id}`}
                              onChange={(newValue) => {
                                handleCategoryChange(dataSet.id, newValue.value);
                              }}
                              placeholder="Select Brand"
                              /* value={selectedCategories[dataSet.id] || ""} */
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              options={brand_options}
                              styles={{
                                container: (base) => ({ ...base, width: '200px',
                                  "@media screen and (max-width: 767px)":{
                                    width: '280px',
                                  }
                                 }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "white"
                                    : "white",
                                  border: 0,
                                  color: "#101010",
                                  /* opacity: "0.3", */
                                  fontSize: "1.375rem",
                                }),
                                menu: (provided, state) => ({
                                  ...provided,
                                  border: "none",
                                  boxShadow: "none",
                                  borderRadius: "16px",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isFocused && "white",
                                  color: "#101010",
                                  opacity: "0.8",
                                  fontSize: "1.375rem",
                                }),
                                dropdownIndicator: (base) => ({
                                  ...base,
                                  color: "black", // Custom colour
                                  opacity: "1",
                                }),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="Vehicle_tags_1">
                      <div className="Vechicle_label">
                        <p>Car Model</p>
                      </div>
                      <div className="Vechicle_custom_select">
                        <div className={`Vechicle_select_header`}>
                          <div className="Vechicle_select_button">
                            {/* <select
                              id={`car-model-${dataSet.id}`}
                              onChange={(event) =>
                                handleValueChange(dataSet.id, event, dataSet)
                              }
                              value={selectedValues[dataSet.id] || ""}
                            >
                              <option value="">Select Car Model</option>
                              {selectedCategories[dataSet.id] && (
                                <>
                                  {dataSet.models[
                                    selectedCategories[dataSet.id]
                                  ].map((value, modelindex) => (
                                    <option
                                      key={modelindex}
                                      value={value.excursion_id}
                                    >
                                      {value.excursion_name}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select> */}

                            {model_options && (
                              <Select
                                id={`car-model-${dataSet.id}`}
                                onChange={(newValue) => {
                                  /* handleCategoryChange(dataSet.id, newValue.value); */
                                  handleValueChange(
                                    dataSet.id,
                                    newValue.value,
                                    dataSet
                                  );
                                }}
                                placeholder="Select Car Model"
                                /* value={selectedValues[dataSet.id] || ""} */
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                options={model_options[dataSet.id]}
                                styles={{
                                  container: (base) => ({ ...base, width: '250px',
                                    "@media screen and (max-width: 767px)":{
                                      width: '280px',
                                    }
                                   }),
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "white"
                                      : "white",
                                    border: 0,
                                    color: "#101010",
                                    /* opacity: "0.3", */
                                    fontSize: "1.25rem",
                                  }),
                                  menu: (provided, state) => ({
                                    ...provided,
                                    border: "none",
                                    boxShadow: "none",
                                    borderRadius: "16px",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused && "white",
                                    color: "#101010",
                                    opacity: "0.8",
                                    fontSize: "1.25rem",
                                  }),
                                  dropdownIndicator: (base) => ({
                                    ...base,
                                    color: "black", // Custom colour
                                    opacity: "1",
                                  }),
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Vechicle_Verticle_Line"></div>

                  <div className="Vehicle_Total">
                    <div className="Vechicle_ToatalFixPart_Departure">
                      <div className="Vehicle_Total_Tag1">
                        <div>
                          <p>Departure</p>
                        </div>
                        {selectedValues[dataSet.id] ? (
                          <>
                            <h1>₹ {selectedModelVal[dataSet.id].price}</h1>
                          </>
                        ) : (
                          <h4>-</h4>
                        )}
                      </div>
                    </div>
                    <div className="Vechicle_Verticle_Line_mob"></div>
                    <div className="Vechicle_ToatalFixPart_return">
                      <div className="Vehicle_Total_Tag1">
                        {triptype === "return" && (
                          <div>
                            <p>Return</p>
                          </div>
                        )}
                        {triptype === "return" &&
                        selectedReturnValues[dataSet.id] ? (
                          <>
                            <h1>₹ {selectedReturnModelVal[dataSet.id].price}</h1>
                          </>
                        ) : (
                          <>{triptype === "return" && <h4>-</h4>}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {formData &&
                [...Array(formData.onwardJourney.vehicleData.Scootercount)].map(
                  (_, index) => (
                    <div className="Vehicle_Row" key={index}>
                      <div className="Vehicle_img_info">
                        <div className="Vehicle_images">
                          <img
                            src="/assets/img/service/Bike.png"
                            alt="4 wheeler"
                          />
                        </div>
                        <div className="Vehicle_infos">
                          <div>
                            <h1>2 Wheelers</h1>
                          </div>
                          <div>
                            <p>BIKE {index + 1}</p>
                          </div>
                        </div>
                      </div>
                      <div className="Vehicle_tags1">
                        <div className="Vehicle_tags1_laber">
                          <p>Vehicle</p>
                        </div>
                        <div className="Vehicle_tags1_input">
                          {/* Render the scooter count dynamically */}
                          <input
                            type="text"
                            // placeholder={`Bike ${index + 1}`}
                            placeholder="Bike"
                            readOnly
                            style={{ cursor: "context-menu" }}
                          />
                        </div>
                      </div>

                      <div className="Vechicle_Verticle_Line"></div>

                      <div className="Vehicle_Total">
                        <div className="Vehicle_Total_Tag1">
                          <div>
                            <p>Departure</p>
                          </div>
                          <h1>{`₹ ${onwardBike?.price || "0"}`}</h1>
                        </div>
                        {/* Render the return tag based on triptype */}
                        {formData.triptype === "return" && (
                          <div className="Vehicle_Total_Tag1">
                            <div>
                              <p>Return</p>
                            </div>
                            <h1>{`₹ ${returnBike?.price || "0"}`}</h1>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
              {/* for CycleCount add a array */}
              {formData &&
                [...Array(formData.onwardJourney.vehicleData.Cyclecount)].map(
                  (_, index) => (
                    <div className="Vehicle_Row" key={index}>
                      <div className="Vehicle_img_info">
                        <div className="Vehicle_images">
                          <img
                            src="/assets/img/service/cycle.png"
                            alt="4 wheeler"
                          />
                        </div>
                        <div className="Vehicle_infos">
                          <div>
                            <h1>2 Wheelers</h1>
                          </div>
                          <div>
                            <p>CYCLE {index + 1}</p>
                          </div>
                        </div>
                      </div>
                      <div className="Vehicle_tags1">
                        <div className="Vehicle_tags1_laber">
                          <p>Vehicle</p>
                        </div>
                        <div className="Vehicle_tags1_input">
                          {/* Render the scooter count dynamically */}
                          <input
                            type="text"
                            // placeholder={`Cycle ${index + 1}`}
                            placeholder="Cycle"
                            readOnly
                            style={{ cursor: "context-menu" }}
                          />
                        </div>
                      </div>

                      <div className="Vechicle_Verticle_Line"></div>

                      <div className="Vehicle_Total">
                        <div className="Vehicle_Total_Tag1">
                          <div>
                            <p>Departure</p>
                          </div>
                          <h1>{`₹ ${onwardCycle?.price || "0"}`}</h1>
                        </div>
                        {/* Render the return tag based on triptype */}
                        {formData.triptype === "return" && (
                          <div className="Vehicle_Total_Tag1">
                            <div>
                              <p>Return</p>
                            </div>
                            <h1>{`₹ ${returnCycle?.price || "0"}`}</h1>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
            </div>
          )} 
        </div>
        <Modal isOpen={isBrowserAlerts}  contentLabel="Example Modal" className="custom-modal-alert">
            <p>{isBrowserAlerts}</p> 
            <div className="ModalButton"><button onClick={closeModal}>Ok</button></div>
        </Modal>  
      <FlowWidget
        showOnlyMobile={true}
        showOnlyDesktop={true}
        currentPageConfirmtion={true}
        handleButtonClick={handleButtonClick}
      />
    </>
  );
};

export default VehicleFlow;
