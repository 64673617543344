import React from 'react'
import "./BookOnGo.css";

const BookOnGo = () => {
    const openLinkApp = () => {
        const urlToNavigate = "https://play.google.com/store/apps/details?id=com.m2m.ferries&hl=en_IN";
        // window.location.href = urlToNavigate;
        window.open(urlToNavigate, "_blank");
    };
  return (
        <div className="BookOnGo_ScannerContiner">
            <div className="BookOnGo_Complogo">
                <div className="BookOnGo_grid">
                    <div className="BookOnGo_CompHeading">
                        <h2>Book on the Go</h2>
                        <div className="BookOnGo_seven">
                        <div className="section section-1_BookOnGo"></div>
                        <div className="section section-2_BookOnGo"></div>
                        <div className="section section-3_BookOnGo"></div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="BookOnGo_Scanner">
                <div className="BookOnGo_Scanner_DivOne">    
                    <div className="BookOnGo_Scanner_DivOne_Heading">
                       <h2>Download<br></br>The M2M Ferries App</h2>
                    </div>
                    <div className="BookOnGo_Scanner_DivOne_QRcode">
                        <img src="/assets/img/Scanner/Qr_Code.png" alt="Download The M2M Ferries App" />
                        <p>Scan Now</p>
                    </div>
                    <div className="BookOnGo_Scanner_DivOne_stores" style={{cursor:'pointer'}}>
                        <img src="/assets/img/Scanner/PlayStore.png"alt="play store"onClick={openLinkApp}/>
                        <img src="/assets/img/Scanner/AppleStore.png"alt="apple store"onClick={openLinkApp}/>
                    </div>
                </div>
                <div className="BookOnGo_Scanner_DivTwo">
                    <img src="/assets/img/Scanner/M2M-Mobile-App-Mockup.png" alt="Book on the go" />
                </div>
            </div>

        </div>
  )
}

export default BookOnGo