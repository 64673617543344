import React, { useEffect, useRef, useState } from "react";
import "./Explore_Comman_slider_Tabs.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StaticDataService from "../../services/staticData";
import { M2MApiUrl } from "../../services/staticData";
import { useParams } from 'react-router-dom';

const Explore_Comman_slider_Tabs = () => {
  const { postName } = useParams();
  const { category } = useParams();

  const [currentPageDesktop, setCurrentPageDesktop] = useState(1);
  const [currentPageMobile, setCurrentPageMobile] = useState(1);
  const sliderRefDesktop = useRef();
  const sliderRefMobile = useRef();
  const [subPageData, setSubPageData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    StaticDataService.getExplorePosts(category)
      .then((response) => {
        if (response && response.success) {
          setSubPageData(response.explorePosts);
        } else {
          console.error("Error in response:", response);
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // const filteredData = subPageData ? subPageData.filter((item) => item.slug !== postName) : [];
  const filteredData = subPageData ? subPageData.filter(function(item) {
    return item.slug !== postName;
  }) : [];
  // return item && item.category === category && item.slug !== postName;
  
  const totalPageDesktop = Math.ceil(filteredData.length / 3); 
  const totalPageMobile = Math.ceil(filteredData.length / 2); 

  const settingsDeskTop = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (currentSlide) => {
      setCurrentPageDesktop(Math.ceil(currentSlide / 3) + 1); 
    },
  };
  
  const settingsMobile = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (currentSlide) => {
      setCurrentPageMobile(Math.ceil(currentSlide) + 1);
    },
  };

  const goToNextDeskTop = () => {
    if (sliderRefDesktop.current) {
      sliderRefDesktop.current.slickNext();
    }
  };

  const goToPrevDeskTop = () => {
    if (sliderRefDesktop.current) {
      sliderRefDesktop.current.slickPrev();
    }
  };

  const goToNextMobile = () => {
    if (sliderRefMobile.current) {
      sliderRefMobile.current.slickNext();
    }
  };

  const goToPrevMobile = () => {
    if (sliderRefMobile.current) {
      sliderRefMobile.current.slickPrev();
    }
  };

  // console.log("subPageData :",subPageData && subPageData);
  const handleNavigation = (slug) => {
    // console.log('Navigating to:', `/explore/${category}/${slug}`);
    //navigate(`/explore/${category}/${slug}`)
    // window.location.href = `/explore/${category}/${slug}`;
    const url = `/explore/${category}/${slug}`;
    window.open(url, '_blank');
  };
  return (
    <>
    {filteredData && filteredData.length > 0 ? (
      <div className="explore-comman-slider-section">
      {loading ? (
        <div></div> 
      ) : (
        <>
          <h1>Restaurants At Mandwa Port</h1>
          <div className="about-mandwa-heading-bottam-line">
            <div className="section bottam-line-1"></div>
            <div className="section bottam-line-2"></div>
            <div className="section bottam-line-3"></div>
          </div>

          <div className="slider_container">
            <div className="explore-comman-slider-desktop">
              <Slider ref={sliderRefDesktop} {...settingsDeskTop}>
                {filteredData.map((item, index) => (
                  
                  <div key={index} className="slides" onClick={() => handleNavigation(item.slug)}>
                    <div className="borderRadiusZoomIN">
                      <img src={`${M2MApiUrl}${item.hero_image.replace("public/", "storage/")}`} alt={item.title}/>
                    </div>
                    <div className="information-comman-slider">
                      <div className="information-comman-slider-div-1">
                        <h1>{item.title}</h1>
                        <p>
                          <span>{item.price_text}</span> ₹ {item.price}
                        </p>
                      </div>
                      <div className="information-comman-slider-div-2">
                        <div className="information-comman-slider-div-2-rating-div-2">
                          <h2>{item.rating}</h2>
                          <div className="rating-star-svg">
                            {Array.from({ length: 5 }, (el, starIndex) => {
                              if (starIndex < Math.floor(item.rating)) {
                                // **Full Star**
                                return (
                                  <svg key={starIndex} xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                                    <path d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                                  </svg>
                                );
                              } else if (
                                starIndex === Math.floor(item.rating) &&
                                item.rating % 1 !== 0
                              ) {
                                // **Half Star**
                                return (
                                  <svg key={starIndex} xmlns="http://www.w3.org/2000/svg" width="11.992" height="23.231" viewBox="0 0 11.992 23.231">
                                    <path d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219V.19A3.227,3.227,0,0,0,8.927,2.432L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                                  </svg>
                                );
                              } else {
                                // **Empty Star**
                                return (
                                  <svg key={starIndex} xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                                    <path d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#767c8d" opacity="0.5"/>
                                  </svg>
                                );
                              }
                            })}
                          </div>
                          <h5>{item.rating_source}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                ))}
              </Slider>

              {/* **Pagination Controls** */}
              <div className="pagination_Explore_about">
                <div className={`prev_button_eight_about ${currentPageDesktop === 1 ? "disabled" : ""}`}  onClick={currentPageDesktop > 1 ? goToPrevDeskTop : null}>
                  <img src="/assets/img/Partners/arrow.png" alt="prev" />
                </div>

                <div className="page_indicator_about">
                  {String(currentPageDesktop).padStart(2,'0')}/{String(totalPageDesktop).padStart(2, '0')}
                </div>

                <div className={`next_button_eight_about ${currentPageDesktop === totalPageDesktop ? "" : ""}`} onClick={currentPageDesktop < totalPageDesktop ? goToNextDeskTop : null}>
                  <img src="/assets/img/Partners/arrow.png" alt="next" />
                </div>
              </div>
            </div>

            {/* Mobile Slider */}
            <div className="explore-comman-slider-mobile">
              <Slider ref={sliderRefMobile} {...settingsMobile}>
                {filteredData && filteredData.reduce((resultArray, item, index, array) => {
                  // Push pairs of items into resultArray
                  if (index % 2 === 0) {
                    resultArray.push(array.slice(index, index + 2)); 
                  }
                  return resultArray;
                }, []).map((pair, index) => (
                  <div key={index} className="slides" onClick={() => handleNavigation(pair[0].slug)}> {/* Click on the first item to navigate */}
                    <div className="borderRadiusZoomIN">
                      {pair.map((item, subIndex) => (
                        <div key={subIndex} className="item-container"> 
                          <img src={`${M2MApiUrl}${item.hero_image.replace("public/", "storage/")}`} alt={item.title} />
                          <div className="information-comman-slider">
                            <div className="information-comman-slider-div-1">
                              <h1>{item.title}</h1>
                              <p>
                                <span>{item.price_text}</span> ₹ {item.price}
                              </p>
                            </div>
                            <div className="information-comman-slider-div-2">
                              <div className="information-comman-slider-div-2-rating-div-2">
                                <h2>{item.rating}</h2>
                                <div className="rating-star-svg">
                                  {Array.from({ length: 5 }, (el, starIndex) => {
                                    if (starIndex < Math.floor(item.rating)) {
                                      return (
                                        <svg key={starIndex} xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                                          <path d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                                        </svg>
                                      );
                                    } else if (starIndex === Math.floor(item.rating) && item.rating % 1 !== 0) {
                                      return (
                                        <svg key={starIndex} xmlns="http://www.w3.org/2000/svg" width="11.992" height="23.231" viewBox="0 0 11.992 23.231">
                                          <path d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219V.19A3.227,3.227,0,0,0,8.927,2.432L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                                        </svg>
                                      );
                                    } else {
                                      return (
                                        <svg key={starIndex} xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                                          <path d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#767c8d" opacity="0.5"/>
                                        </svg>
                                      );
                                    }
                                  })}
                                </div>
                                <h5>{item.rating_source}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </Slider>
              
              {/* Pagination Controls */}
              <div className="pagination_Explore_about">
                <div className={`prev_button_eight_about ${currentPageMobile === 1 ? "disabled" : ""}`} onClick={currentPageMobile > 1 ? goToPrevMobile : null}>
                  <img src="/assets/img/Partners/arrow.png" alt="prev" />
                </div>

                <div className="page_indicator_about">
                  {String(currentPageMobile).padStart(2, '0')}/{String(totalPageMobile).padStart(2, '0')}
                </div>

                <div className={`next_button_eight_about ${currentPageMobile === totalPageMobile ? "" : ""}`} onClick={currentPageMobile < totalPageMobile ? goToNextMobile : null}>
                  <img src="/assets/img/Partners/arrow.png" alt="next" />
                </div>
              </div>
            </div>

          </div>
        </>
      )}
    </div>
    ):null}
    </>
  );
};

export default Explore_Comman_slider_Tabs;
