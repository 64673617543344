import React, { useRef, useState } from "react";
import "./Testimonials_com.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StaticDataService from "../../services/staticData";
import { useEffect } from "react";
const Testimonials_com = () => {

  const [subTestimonialsData, setSubTestimonialsData] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    StaticDataService.getTestimonials()
      .then((response) => {
        // console.log("Testimonials response:", response);
        if (response && response.success) {
          setSubTestimonialsData(response.testimonialData);
        } else {
          console.error("Error in response:", response);
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
      })
      .finally(() => {setLoading(false);});
  }, []);

  // console.log("subTestimonialsData", subTestimonialsData);

  const [currentPage, setCurrentPage] = useState(1);
  const sliderRef = useRef(null);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "150px",
    slidesToShow: 1,
    variableWidth: true,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
          variableWidth: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
          variableWidth: false,
        }
      }
    ]
  };

  const goToNextPagetest = () => {
    if (currentPage < subTestimonialsData.length) {
      setCurrentPage(currentPage + 1);
      sliderRef.current.slickNext();
    }
  };

  const goToPrevPagetest = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div className="Nine_comp_Main_Container">
      <div className="Nine_Complogo">
        <div className="NineComp_Slider">
          <div className="Nine_CompHeading">
            <h2>What Our Passengers Say About Us </h2>
            <div className="heading_Nine">
              <div className="section section-1_Nine"></div>
              <div className="section section-2_Nine"></div>
              <div className="section section-3_Nine"></div>
            </div>
          </div>
        </div>
      </div>
      {/* slider are continue */}
      <div className="slider_container_Nine">
        {subTestimonialsData && subTestimonialsData.length > 0 ? (
          <>
            <Slider {...settings} ref={sliderRef}>
              {subTestimonialsData.map((slide) => (
                <div key={slide.id} className="slide_Nine">
                  {/* <img src={slide.imageSrc} alt={`Slide ${slide.id}`} /> */}
                  <p>{slide.comment}</p>
                  <h2>{slide.name}</h2>
                </div>
              ))}
            </Slider>
            <div className="slider_nav_Nine">
              <div 
                className={`prevArrow_Nine ${currentPage === 1 ? "disabled" : ""}`} 
                onClick={goToPrevPagetest} 
                disabled={currentPage === 1}
              >
                <img src="/assets/img/Partners/arrow.png" alt="Previous" />
              </div>
              <p className="PtagToatalPages">
                {`${String(currentPage).padStart(2, '0')}/${String(subTestimonialsData.length).padStart(2, '0')}`}
              </p>
              <div 
                className={`nextArrow_Nine ${currentPage === subTestimonialsData.length ? "disabled" : ""}`} 
                onClick={goToNextPagetest} 
                disabled={currentPage === subTestimonialsData.length}
              >
                <img src="/assets/img/Partners/arrow.png" alt="Next" />
              </div>
            </div>
          </>
        ) : (
          <div>Loading testimonials...</div>
        )}
      </div>
    </div>
  );
};

export default Testimonials_com;
