import React, { useState, useRef } from 'react';

const MainCode = () => {
  const [isExpanded, setIsExpanded] = useState("");
  const [rotateArrow, setRotateArrow] = useState(false);
  const expandedContentRef = useRef(null);
  const [selectedcat, setSelectedcat] = useState({ sailing_time: "", catid: "" });

  const toggleExpand = (value) => {
    if (isExpanded === value) {
      setIsExpanded("");
      setRotateArrow(!rotateArrow);
    } else {
      setIsExpanded(value);
      setRotateArrow(!rotateArrow);
    }
  };

  const setTripCategory = (categoryId, sailingTime, sailingDate) => {
    setSelectedcat({
      catid: categoryId,
      sailing_time: sailingTime,
      sailing_date: sailingDate
    });
  };

  // Sample hardcoded data
  const trips = [
    {
      index: 0,
      cruise_name: "Morning Cruise",
      cruise_id: 42,
      sailing_time: "08:30:00",
      sailing_date: "03/20/2025",
      start_port: "Port A",
      end_port: "Port B",
      has_intermediate_stops: true,
      availablity: [
        {
          category_id: 1,
          available_room: 15,
          total_room: 20,
          single_room_price: "1500.00"
        },
        {
          category_id: 2,
          available_room: 8,
          total_room: 30,
          single_room_price: "2500.00"
        },
        {
          category_id: 3,
          available_room: 0,
          total_room: 10,
          single_room_price: "3500.00"
        },
        // {
        //   category_id: 4,
        //   available_room: 0,
        //   total_room: 10,
        //   single_room_price: "3500.00"
        // }
      ]
    }
  ];

  const tripsData = {
    1: {
      category_name: "Standard Deck",
      description: "<ul><li>Access to main deck</li><li>Comfortable seating</li><li>On-board refreshments</li><li>Scenic views</li></ul>",
      feeling_threshold: 50,
      start_date: "01/01/2025",
      end_date: "12/31/2025",
      status: 0,
      disable_message_desktop: ""
    },
    2: {
      category_name: "Premium Deck",
      description: "<ul><li>All Standard features</li><li>Premium seating</li><li>Complimentary drinks</li><li>Priority boarding</li></ul>",
      feeling_threshold: 40,
      start_date: "01/01/2025",
      end_date: "12/31/2025",
      status: 0,
      disable_message_desktop: ""
    },
    3: {
      category_name: "VIP Experience",
      description: "<ul><li>All Premium features</li><li>Private area access</li><li>Personal attendant</li><li>Gourmet refreshments</li></ul>",
      feeling_threshold: 30,
      start_date: "01/01/2025",
      end_date: "12/31/2025",
      status: 0,
      disable_message_desktop: "VIP Experience available from April 1, 2025"
    },
    // 4: {
    //     category_name: "ANK Experience",
    //     description: "<ul><li>All Premium features</li><li>Private area access</li><li>Personal attendant</li><li>Gourmet refreshments</li></ul>",
    //     feeling_threshold: 30,
    //     start_date: "01/01/2025",
    //     end_date: "12/31/2025",
    //     status: 0,
    //     disable_message_desktop: "VIP Experience available from April 1, 2025"
    //   },
  };

  const nameMap = {
    "Port A": "Gateway Harbor",
    "Port B": "Sunset Bay",
    "Port C": "Middle Point"
  };

  const vehicleAvailabilityArr1 = [
    {
      accesstime: "03/20/2025 08:30:00",
      carAvailabilityText: "Available",
      bikeAvailabilityText: "Limited"
    }
  ];

  const hasIntermediateStops = (trip) => {
    return trip.has_intermediate_stops;
  };

  const index = 0; // Using a fixed index for this simplified version

  return (
    <div className="schedule-wrapper" key={`trip1${index}`}>
      <div className="trip-timing_princess">
        <div className={`bigFlowPra ${isExpanded === index ? "expanded" : ""}`}>
          <div className="hading_btn_FlowPra">
            <h1>{trips[0].cruise_name}</h1>
            <div className="hading_btn_Flow_btnPra" onClick={() => toggleExpand(index)}>
              <button>
                {isExpanded === index ? "View Less" : "View More"}
              </button>
              <span id="bounce">
                {rotateArrow ? (
                  <svg fill="#10b2d1" height="12px" width="12px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xmlSpace="preserve" stroke="#10b2d1" strokeWidth="33">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.66"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path id="XMLID_93_" d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394 l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393 C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"></path>{" "}
                    </g>
                  </svg>
                ) : (
                  <svg fill="#10b2d1" height="12px" width="12px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xmlSpace="preserve" stroke="#10b2d1" strokeWidth="33">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path id="XMLID_102_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"></path>{" "}
                    </g>
                  </svg>
                )}
              </span>
            </div>
          </div>

          <div className={`appointment ${isExpanded === index ? "hidden" : ""}`}>
            <div className="Timing_Paltform">
              <div className="timingSlot_start">
                <h3>08:30 AM</h3>
                <p>{nameMap[trips[0].start_port]}</p>
              </div>
              
              {hasIntermediateStops(trips[0]) && (
                <div className="timingSlot_start">
                  <h3>9:30 AM</h3>
                  <p>Middle</p>
                </div>
              )}
              
              <div className="timingSlot_start">
                <h3>10:30 AM</h3>
                <p>{nameMap[trips[0].end_port]}</p>
              </div>
            </div>
            
            <div className="DashedLine">
              <div className="time_slot_one">
                <div className="dot"></div>
                <div className="line"></div>
              </div>
              
              {hasIntermediateStops(trips[0]) ? (
                <div className="time_slot_one">
                  <div className="line"></div>
                  <div className="dot"></div>
                  <div className="line"></div>
                </div>
              ) : (
                <p>1 Hr Non Stop</p>
              )}
              
              <div className="time_slot_two">
                <div className="line"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>

          <div className={`appointment_vertical ${isExpanded === index ? "display-flex" : "hidden"}`} ref={expandedContentRef}>
            <div className="DashedLine_vertical">
              <div className="time_slot_one_vertical">
                <div className="dot_vertical"></div>
                <div className="line_vertical"></div>
                
                {hasIntermediateStops(trips[0]) && (
                  <>
                    <div className="dot_vertical"></div>
                    <div className="line_vertical"></div>
                  </>
                )}
                
                <div className="dot_vertical"></div>
              </div>
            </div>
            
            <div className="Timing_Paltform_vertical">
              <div className="timingSlot_start">
                <h3>08:30 AM</h3>
                <p>{nameMap[trips[0].start_port]}</p>
              </div>
              
              {hasIntermediateStops(trips[0]) ? (
                <div className="timingSlot_start">
                  <h3>09:30 AM</h3>
                  <p>Middle Point</p>
                </div>
              ) : (
                <p>1hr Non Stop</p>
              )}
              
              <div className="timingSlot_start">
                <h3>10:30 AM</h3>
                <p>{nameMap[trips[0].end_port]}</p>
              </div>
            </div>
          </div>

          <div className="M2MBookFlow_imgPra" key={index}>
            <div className="M2MBookFlow_img_div1Pra">
              <img src="/assets/img/FlowImg/car.png" alt="car"/>
              <p>Available</p>
            </div>
            <div className="vertical_line_imgPra" key={index}></div>
            <div className="M2MBookFlow_img_div1Pra">
              <img src="/assets/img/Widget/Scooter2.0.png" alt="scooter"/>
              <p>Limited</p>
            </div>
          </div>

          {hasIntermediateStops(trips[0]) && (
            <div key={`tripcruis${trips[0].cruise_id}${index}`} className="LAstNotePrincess">
              <p>*wheelchair and pets not applicable</p>
            </div>
          )}
        </div>
      </div>

      <div className="trip-categories_princess">
        {trips[0].availablity.map((availablity, index1) => (
          <div key={`avail${index1}`} className={`boxFlow${index1 + 1} ${trips[0].availablity.length === 3 ? "M2MOne_Div" : "PrincessDiv"} ${isExpanded !== index && !(availablity.available_room > 0) ? "unavailable" : ""}`}>
            <div 
              after-dynamic-value={`${tripsData[availablity.category_id]?.disable_message_desktop || ""}`}
              className={`ExpandHiddenContentUlPra ${isExpanded === index ? 
                `${availablity.available_room < 1 ? "soldOut" : 
                availablity.available_room === 0 ? "comingSoon" : ""}` : ""} 
                ${tripsData[availablity.category_id]?.disable_message_desktop ? "trip-unavailable" : ""}`}
            >
              <div className="hading_btn_Flow1 no-opacity" onClick={() => toggleExpand(index)}>
                <h1 className="category-name">{tripsData[availablity.category_id].category_name}</h1>

                {availablity.available_room > 0 ? (
                  (availablity.available_room / availablity.total_room) * 100 >= tripsData[availablity.category_id].feeling_threshold ? (
                    <div className="commanTagsClass bg_Green">
                      <div className="CommanWhiteDot_Labels"></div>
                      <p>Available</p>
                    </div>
                  ) : (
                    <div className="commanTagsClass bg_feelingFast">
                      <div className="CommanWhiteDot_Labels"></div>
                      <p>Filling fast</p>
                    </div>
                  )
                ) : (
                  <div className="commanTagsClass bg_SoldOut">
                    <div className="CommanWhiteDot_Labels"></div>
                    <p>{tripsData[availablity.category_id].disable_message_desktop ? "Unavailable" : "Sold Out"}</p>
                  </div>
                )}
              </div>
                 
              {isExpanded === index && (
                <div className="no-opacity" dangerouslySetInnerHTML={{__html: tripsData[availablity.category_id].description}} />
              )}
              
              <div className={`FlowPriceTagCheckboxPra no-opacity ${availablity.available_room < 1 && tripsData[availablity.category_id].disable_message_desktop ? "no-visibility" : ""}`}>
                <div className="Round_Box_PricePRa" onClick={() => setTripCategory(availablity.category_id, trips[0].sailing_time, trips[0].sailing_date)}>
                  <input 
                    type="radio" 
                    name="price_time" 
                    className="round_checkboxFlowa"
                    checked={selectedcat.sailing_time === trips[0].sailing_time && selectedcat.catid === availablity.category_id}
                    onChange={() => setTripCategory(availablity.category_id, trips[0].sailing_time, trips[0].sailing_date)}
                    value={`${availablity.category_id}_${trips[0].sailing_time}_${trips[0].sailing_date}`}
                    disabled={!(availablity.available_room > 0)}
                  />
                  <h5>₹{availablity.single_room_price.replace(".00","")}</h5>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainCode;