import React, { useState, useRef, useEffect } from "react";
import "./ReturnFlow.css";
import { Link } from "react-router-dom";
import moment from "moment";
import TripDataService from "../../services/TripData";
import FlowWidget from "./CommanComp/FlowWidget";
import Lottie from "lottie-react";
import animationData from "../../animation-1.json";
import Modal from 'react-modal';
import CalenderLine from "./CommanComp/CalenderLine";


const DepartureFlow = () => {
  Modal.setAppElement('#root');

  const [isExpanded, setIsExpanded] = useState("");
  const [rotateArrow, setRotateArrow] = useState(false);
  const expandedContentRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [selectedcat, setSelectedcat] = useState(false);
  const vehicleAvailabilityArr = [];
  const [vehicleAvailabilityArr1, setVehicleAvailabilityArr1] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Sort By");
  const options = ["High to Low","Low to High","Earliest"];
  const nameMap = { MUM: "Mumbai", MWA: "Mandwa" };
  const [trips, setTrips] = useState([]);
  const [actualtrips, setActualTrips] = useState([]);
  const [tripsData, setTripsData] = useState([]);
  const [error, setError] = useState([]);
  const [petObject, setPetObject] = useState([]);
  const [storageData, setStorageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showData, setShowData] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Fetching Onward Trips...');

  useEffect(() => {
    if (shouldScroll && expandedContentRef.current) {
      expandedContentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  const toggleExpand = (value, value1 = "") => {
    if (isExpanded === value) {
      setIsExpanded("");
      setRotateArrow(!rotateArrow);
    } else {
      setIsExpanded(value);
      setRotateArrow(!rotateArrow);
    }
  };
  var sailingObject;
  

  const setTripCategory = (category_id, sailing_time, sailing_date) => {
    var storageData = JSON.parse(localStorage.getItem("formData"));
    const hasPets = storageData?.onwardJourney?.passengersData?.Petcount > 0 || false;
  
    const selectedTrip = trips.find(sailing => sailing.sailing_time === sailing_time);
    if (selectedTrip && selectedTrip.cruise_id === 80 && hasPets) {
      setIsOpenBrowserAlerts(true);
      setIsBrowserAlerts("Pets are not allowed on Princess vessel. Please select another vessel.");
      return;
    }
  
    trips.forEach((sailing) => {
      if (sailing.sailing_time == sailing_time) {
        sailingObject = sailing;
        sailing.availablity.forEach((availablity) => {
          if (availablity.category_id === category_id) {
            sailingObject.available = availablity;
          }
        });
      }
    });
  
    if (sailingObject) {
      petObject.forEach((pet) => {
        if (pet.sailing_time === sailing_time) {
          sailingObject.petinfo = pet.info;
        }
      });
      
      storageData.onwardJourney.SelTripData = sailingObject;
      localStorage.setItem("formData", JSON.stringify(storageData));
  
      var selectedcat = {
        catid: storageData.onwardJourney.SelTripData.available.category_id,
        sailing_time: storageData.onwardJourney.SelTripData.sailing_time,
      };
      setSelectedcat(selectedcat);
  
      const event = new Event("localStorageUpdated");
      window.dispatchEvent(event);
    }
  };

  const sortData = (option) => {
    let sorted;
    switch (option) {
      case "Low to High":
        sorted = [...trips].sort((a, b) => {
          const minPriceA = Math.min(...a.availablity.map(trip => parseFloat(trip.single_room_price)));
          const minPriceB = Math.min(...b.availablity.map(trip => parseFloat(trip.single_room_price)));
          return minPriceA - minPriceB;
        });
        break;
      case "High to Low":   
        sorted = [...trips].sort((a, b) => {
          const minPriceA = Math.min(...a.availablity.map(trip => parseFloat(trip.single_room_price)));
          const minPriceB = Math.min(...b.availablity.map(trip => parseFloat(trip.single_room_price)));
          return minPriceB - minPriceA;
        });
        break;
      case "Earliest":
        sorted = [...trips].sort((a, b) => {
          const timeA = moment(a.sailing_time, 'hh:mm:ss');
          const timeB = moment(b.sailing_time, 'hh:mm:ss');
          return timeA - timeB;
        });
        break;
      default:
        sorted = trips;
    }
    setTrips(sorted);
    // console.log("sorted :",sorted)
  };

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    sortData(option);
  };

  // Data Access to Api
  useEffect(() => {
    var storageData = JSON.parse(localStorage.getItem("formData"));
    setStorageData(storageData);
    var selvehicleData = storageData.onwardJourney.vehicleData;
    if (storageData.onwardJourney.SelTripData) {
      var selectedcat = {
        catid: storageData.onwardJourney.SelTripData.available.category_id,
        sailing_time: storageData.onwardJourney.SelTripData.sailing_time,
      };
      setSelectedcat(selectedcat);
    }
    var sailing_date = moment().format("MM/DD/YYYY");

    if (localStorage.getItem("formData")) {
      sailing_date = storageData.onwardJourney.selectedDateNew1;
    } else {
      window.location = "/";
    }
    var route_filterval = storageData.onwardJourney.selectedFromAbbreviation + "-" + storageData.onwardJourney.selectedToAbbreviation;
    var rawData = JSON.stringify({
      sailing_date: sailing_date,
      route_filter: route_filterval,
      live_data: true,
    });
    setTrips([]);
    setActualTrips([]);

    // tripdata api
    
    TripDataService.fetchTrips(rawData)
      .then((response) => {
        if (response.status !== "error") {
          setShowData(true);
          const data = response.sailings;          
          const tripResult = data.filter(d => 
            moment.utc(moment(d.sailing_date + " " + d.sailing_time, "MM/DD/YYYY hh:mm:ss")).isAfter(moment(new Date()))
          );
          //setTrips(tripResult);

          setError("");
          setLoading(false);


          // console.log("data :",data)
          const uniqueCruiseIds = [...new Set(data.map(item => item.cruise_id))];

          var petobj = [];

          const tripPromises = uniqueCruiseIds.map((cruise_id) => {
              const VehicleData = JSON.stringify({
                  cruise_id,
                  sailing_date,
                  sailing_type: '1_way',
                  sailing_way: 'onward',
              });


              return TripDataService.fetchMultipleTripVehicles(VehicleData)
              .then((response) => {
                  if (response.status !== 'error') {
                      // Assuming selvehicleData is available and contains the vehicle data
                      const vehiclesTemp = {
                          car: selvehicleData.Carcount,
                          cycle: selvehicleData.Cyclecount,
                          bike: selvehicleData.Scootercount,
                      };

                      const vehicleAvailabilityStatus = processExcursionData(response, vehiclesTemp);

                      // Now merge vehicleAvailability into the matching trips based on sailing_date and sailing_time
                      tripResult.forEach((trip) => {
                          const tripDateTime = moment.utc(`${trip.sailing_date} ${trip.sailing_time}`, "MM/DD/YYYY HH:mm:ss");
                          vehicleAvailabilityStatus.forEach((availability) => {
                              const availabilityDateTime = moment.utc(`${availability.sailing_date} ${availability.sailing_time}`, "MM/DD/YYYY HH:mm:ss");
                              
                              if (tripDateTime.isSame(availabilityDateTime)) {
                                  // Merge the vehicle availability into the corresponding trip
                                  trip.vehicleAvailabilityArr = availability;
                              }
                          });
                      });

                      return tripResult;
                  } else {
                      setError(response.message);
                      return [];
                  }
              });
          });

          Promise.all(tripPromises).then((mergedTrips) => {
              // Update the state after merging vehicle availability into trips
              setTrips(mergedTrips[0]);
              setActualTrips(mergedTrips[0]);
              setLoading(false);
              setError('');
          }).catch((error) => {
              setError(error);
          });

          TripDataService.fetchTripsInfo([])
            .then((response) => {
              if (response.status !== "error") {
                const tripDataInfo = response.tripData
                // console.log("tripDataInfo :",tripDataInfo);
                setTripsData(tripDataInfo);
                setLoading(true);
                // cruise-sailing-pricing-details api
                setError("");
              } else {
                setError(response.message);
              }
            })
            .catch((err) => {
              setError(err.message);
              //setLoading(false);
          });

        } else {
          //setIsBrowserAlerts(response.message);
          setShowData(true);
          setErrorMsg(response.message);
          setError(response.message);
          //setLoading(false);
        }
      })
      .catch((err) => {
        setError(err.message);
        //setLoading(false);
    });
  }, []); 

  const processExcursionData = (data, vehiclesTemp) => {
    return data.excursion_details.map((excursion) => {
      let carTotalAvailable = 0;
      let bikeTotalAvailable = 0;
      let cycleTotalAvailable = 0;
  
      console.log("excursion : ",excursion)

      excursion.onshore_excursion_details.forEach((item) => {
        // console.log("Akash : ",item)
        // Check if it's a Car
        if (item.excursion_type_name === "Car") {
          carTotalAvailable += item.available_quantity;
        }
  
        // Check if it's a Motorcycle/Scooter under Two-wheeler category
        if (
          item.excursion_type_name === "Two-wheeler" &&
          item.excursion_name === "Motorcycle/Scooter"
        ) {
          bikeTotalAvailable += item.available_quantity;
        }

        // Add this check for cycles
        if (
          item.excursion_type_name === "Two-wheeler" && 
          item.excursion_name === "Bicycle"
        ) {
          cycleTotalAvailable += item.available_quantity;
        }
      });
  
      // Get selected quantities from vehiclesTemp
      const selectedCarQty = vehiclesTemp.car || 0; // Selected cars
      const selectedBikeQty = vehiclesTemp.bike || 0; // Selected bikes
      const selectedCycleQty = vehiclesTemp.cycle || 0; // Selected cycles
  
      // Determine car availability status
      let carStatus = "Available";
      if (carTotalAvailable === 0 || selectedCarQty > carTotalAvailable) {
        carStatus = "SOLD OUT";
      } else if (selectedCarQty > 0 && carTotalAvailable <=15) {
        carStatus = "Filling Fast";
      }
  
      // Determine bike availability status
      let bikeStatus = "Available";
      if (bikeTotalAvailable === 0 || selectedBikeQty > bikeTotalAvailable) {
        bikeStatus = "SOLD OUT";
      } else if ( selectedBikeQty > 0 && bikeTotalAvailable <=15) {
        bikeStatus = "Filling Fast";
      }

      // Determine cycle availability status
      let cycleStatus = "Available";
      if (cycleTotalAvailable === 0 || selectedCycleQty > cycleTotalAvailable) {
        cycleStatus = "SOLD OUT";
      } else if ( selectedCycleQty > 0 && cycleTotalAvailable <=15) {
        cycleStatus = "Filling Fast";
      }
  
      return {
        sailing_date: excursion.sailing_date,
        sailing_time: excursion.sailing_time,
        cars: {
          available_quantity: carTotalAvailable,
          status: carStatus,
        },
        bikes: {
          available_quantity: bikeTotalAvailable,
          status: bikeStatus,
        },
        cycles: {
          available_quantity: cycleTotalAvailable,
          status: cycleStatus,
        },
      };
    });
  };
  
  /* const groupBy1 = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }; */

  // Sort trips categories by ticket pricing
  let items = [...trips]
    .sort(function (a, b) {
      return a.cruise_id - b.cruise_id;
    })
    .map((item) => {
      item.availablity.sort(
        (a, b) => a.single_room_price - b.single_room_price
      );
      return item;
    });
    // Sort trips categories by ticket pricing

  // For Mobile Section code
  const [expandedPrincessSections, setExpandedPrincessSections] = useState([]);

  const handlePrincessSectionClick = (wrapperIndex, sectionIndex) => {
    setExpandedPrincessSections((prevSections) => {
      const newSections = [...prevSections];
      // If the clicked section is already expanded, close it
      if (newSections[wrapperIndex] === sectionIndex) {
        newSections[wrapperIndex] = null;
      } else {
        // Otherwise, close all other sections and expand the clicked section
        newSections.fill(null);
        newSections[wrapperIndex] = sectionIndex;
      }

      return newSections;
    });
  };

  // For Browser ALerts
  const [isBrowserAlerts, setIsBrowserAlerts] = useState();
  const [isOpenBrowserAlerts, setIsOpenBrowserAlerts] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const closeBrowserModal = () => {
    setIsBrowserAlerts('');
    setIsOpenBrowserAlerts(false);
  };

  const handleButtonClick = (e) => {
    /* const selectedTripResult = vehicleAvailabilityArr1.filter(d => 
      d.sailing_time == selectedcat.sailing_time
    ); */
    
    if (!selectedcat.catid) {
      setIsOpenBrowserAlerts(true);
      setIsBrowserAlerts("Please select a seating category to proceed.");
      return false;
    } else {
      var storageData = JSON.parse(localStorage.getItem("formData"));
      console.log("selectedVehiclesCnt : ", storageData);

      //return false;
      var selectedVehiclesCnt =  storageData.onwardJourney.vehicleData.Carcount + storageData.onwardJourney.vehicleData.Scootercount + storageData.onwardJourney.vehicleData.Cyclecount;

      if(storageData.onwardJourney.SelTripData.vehicleAvailabilityArr.cars.available_quantity < storageData.onwardJourney.vehicleData.Carcount){
        setIsOpenBrowserAlerts(true);
        setIsBrowserAlerts("Cars for the selected trip time are sold out. Please select a different trip time.");
        return false;
      }
      if(storageData.onwardJourney.SelTripData.vehicleAvailabilityArr.bikes.available_quantity < storageData.onwardJourney.vehicleData.Scootercount){
        setIsOpenBrowserAlerts(true);
        setIsBrowserAlerts("Bikes for the selected trip time are sold out. Please select a different trip time.");
        return false;
      }

      if(storageData.onwardJourney.SelTripData.vehicleAvailabilityArr.cycles.available_quantity < storageData.onwardJourney.vehicleData.Cyclecount){
        setIsOpenBrowserAlerts(true);
        setIsBrowserAlerts("Bicycles for the selected trip time are sold out. Please select a different trip time.");
        return false;
      }
      setErrorMsg("Checking availability, Please wait!");
      setButtonDisabled(true);
      setLoading(true);

      if (storageData.triptype === "onward") {
        var sailing_type = "one_way_onward";
      } else {
        var sailing_type = "custom";
      }

      var rawData = JSON.stringify({
        sailing_date: storageData.onwardJourney.SelTripData.sailing_date,
        sailing_time: selectedcat.sailing_time,
        sailing_type: sailing_type,
        cruise_id: storageData.onwardJourney.SelTripData.cruise_id,
        session_id: "",
      });

      TripDataService.fetchCruiseCategoryAvailability(rawData)
        .then((response) => {
          var selCruiseCatAvailability = [];
          if(storageData.triptype === 'return') {
            selCruiseCatAvailability = response.custom.availability.filter(d=>d.category_id == storageData.onwardJourney.SelTripData.available.category_id);
          }
          else {
            selCruiseCatAvailability = response.one_way_onward.availability.filter(d=>d.category_id == storageData.onwardJourney.SelTripData.available.category_id);
          }

          if (response.status !== "error") {
            storageData.onwardJourney.SelTripData.session_id = response.session_id;
            storageData.onwardJourney.SelTripData.cruiseCatAvailability = selCruiseCatAvailability[0];

            localStorage.setItem("formData", JSON.stringify(storageData));

            var vehicleRawData = JSON.stringify({
              cruise_id: storageData.onwardJourney.SelTripData.cruise_id,
              sailing_date: storageData.onwardJourney.SelTripData.sailing_date,
              sailing_time: storageData.onwardJourney.SelTripData.sailing_time,
              sailing_type: storageData.triptype == "onward" ? "1_way" : "custom",
              sailing_way: "onward",
              channel_phone: "",
              session_id: response.session_id,
            });
            TripDataService.fetchVehicles(vehicleRawData)
              .then((vehicleresponse) => {
                if (vehicleresponse.status == "success") {
                  var onward_sailing_id = vehicleresponse.sailing_id;
                  var onward_route_id = vehicleresponse.route_id;
                  storageData.onwardJourney.SelTripData.vehicleRouteDetails = {};
                  storageData.onwardJourney.SelTripData.vehicleRouteDetails.onward =
                    {
                      onward_sailing_id: onward_sailing_id,
                      onward_route_id: onward_route_id,
                    };

                  vehicleresponse.onshore_excursion_details.filter((item) => {
                    const type = item.excursion_type_name;
                    if (type == "Pet") {
                      storageData.onwardJourney.SelTripData.petinfo = item;
                    }
                  });
                  localStorage.setItem("formData", JSON.stringify(storageData));
                  if (storageData.triptype === "onward") {
                    if (selectedVehiclesCnt > 0) {
                      window.location = "/vehicle";
                    } else {
                      window.location = "/confirmation";
                    }
                  } else {
                    // Redirect to return page if return selected
                    window.location = "/onward-return";
                  }
                } else {
                  setIsBrowserAlerts(response.message);
                  return false;
                }
              })
              .catch((err) => {
                setError(err.message);
              });

            return false;

          } else {
            setIsBrowserAlerts(response.message);
            return false;
          }
        })
        .catch((err) => {
          setError(err.message);
        });
    }
    return false;
  }; 

  const hasIntermediateStops = (trip) => {
    // return trip.cruise_id !== 54; 
    // console.log("Trips-trip :",trip);
    return trip.cruise_id === 54 || trip.cruise_id === 80;
  };

 
  return (
    <>
      <div>
        <div className="OnwardReturnFlow_Desktop">
          <div>
             <CalenderLine journeyType="onward" />
          </div>
          {/* breadcum code start */}
          <div className="Breadcum_Line">
            <div className="Breadcum_Flow">
              <Link to={"/"} className="link-without-underline">
                <div className="Breadcum_Flow_Home" id="depHome">
                  <h1>M2M</h1>
                  <p> Home</p>
                </div>
              </Link>{" "}
              <div className="Breadcum_Flow_Logo">
                <h4>&#62;</h4>
              </div>
              <div
                className="Breadcum_Flow_Departure"
                id="deprture_bredcum_highlight"
              >
                <h1>Departure</h1>
                <p>
                  {storageData.onwardJourney &&nameMap[storageData.onwardJourney.selectedFromAbbreviation]}{" "}To{" "}
                  {storageData.onwardJourney &&nameMap[storageData.onwardJourney.selectedToAbbreviation]}
                </p>
              </div>
            </div>

            <div className="custom_select">
              <div className={`select_header ${isOpen ? "open" : ""}`} onClick={toggleOptions}>
                <div className="select_button">
                  <p>{selectedOption}</p>
                  <span className={`select_button_Unicode ${ isOpen ? "rotate" : ""}`}>&#62;</span>
                </div>
              </div>
              <div className={`options ${isOpen ? "open" : ""}`}>
                {options.map((option, index2) => (
                  <div className="option" key={index2} onClick={() => handleOptionClick(option)}>
                    {option}
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          {/* breadcum code end */}
          {loading && !showData ? (
            <Lottie animationData={animationData} style={{ width: "200px", height: "200px", margin: "auto" }}/>
          ) : (
            <div className="MainDivDeparturePage">
              {trips.length === 0 && loading  ? (
                <div className="NoTripsClass">{errorMsg}</div>
              ) : (
                <div>
                  {trips.map((trip, index) => (
                    <React.Fragment key={`trip${index}`}>
                    {moment.utc(moment(trip.sailing_date + " " + trip.sailing_time, "MM/DD/YYYY hh:mm:ss")).isAfter(moment(new Date())) ?
                    (
                    <div className="schedule-wrapper" key={`trip1${index}`}>
                      <div className="trip-timing_princess">
                        <div className={`bigFlowPra ${isExpanded === index ? "expanded" : ""}`}>
                          <div className="hading_btn_FlowPra">
                            <h1>{trip.cruise_name}</h1>
                            <div className="hading_btn_Flow_btnPra" onClick={() => {toggleExpand(index, "expandclicked");}}>
                              <button>
                                {isExpanded === index ? "View Less" : "View More"}
                              </button>
                              <span id="bounce">
                                {rotateArrow ? (
                                  <svg fill="#10b2d1" height="12px" width="12px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xmlSpace="preserve" stroke="#10b2d1" strokeWidth="33">
                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.66"></g>
                                    <g id="SVGRepo_iconCarrier">
                                      <path id="XMLID_93_" d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394 l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393 C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"></path>{" "}
                                    </g>
                                  </svg>
                                ) : (
                                  <svg fill="#10b2d1" height="12px" width="12px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xmlSpace="preserve" stroke="#10b2d1" strokeWidth="33">
                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                    <g id="SVGRepo_tracerCarrier"strokeLinecap="round" strokeLinejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                      <path id="XMLID_102_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"></path>{" "}
                                    </g>
                                  </svg>
                                )}
                              </span>
                            </div>
                          </div>

                          {/* // New  Good Working Code Start /// */}
                          <div className={`appointment ${isExpanded === index ? "hidden" : ""}`}>
                            <div className="Timing_Paltform">
                              <div className="timingSlot_start">
                                <h3>{moment(trip.sailing_time, ["hh:mm:ss"]).format("hh:mm A")}</h3>
                                <p>{nameMap[trip.start_port]}</p>
                              </div>
                              
                              {/* {trip.cruise_id != 54 && (
                                <div className="timingSlot_start">
                                  <h3>{moment(trip.sailing_time, ["hh:mm:ss"]).format("hh:mm A")}</h3>
                                  <p>{nameMap[trip.start_port]}</p>
                                </div>
                              )} */}
                              
                              <div className="timingSlot_start">
                                <h3>{moment(trip.sailing_date + " " + trip.sailing_time, "MM/DD/YYYY hh:mm:ss").add(1, "hours").format("hh:mm A")}</h3>
                                <p>{nameMap[trip.end_port]}</p>
                              </div>
                            </div>
                            
                            <div className="DashedLine">
                              <div className="time_slot_one">
                                <div className="dot"></div>
                                <div className="line"></div>
                              </div>
                              
                              {trip.cruise_id != 54 ? (
                                // <div className="time_slot_one">
                                //   <div className="line"></div>
                                //   <div className="dot"></div>
                                //   <div className="line"></div>
                                // </div>
                                <p className="onehr-style">1 Hr Non Stop</p>
                              ) : (
                                <p className="onehr-style">1 Hr Non Stop</p>
                              )}
                              
                              <div className="time_slot_two">
                                <div className="line"></div>
                                <div className="dot"></div>
                              </div>
                            </div>
                          </div>

                          <div className={`appointment_vertical ${isExpanded === index ? "display-flex" : "hidden"}`} ref={expandedContentRef}>
                            <div className="DashedLine_vertical">
                              <div className="time_slot_one_vertical">
                                <div className="dot_vertical"></div>
                                <div className="line_vertical"></div>
                                
                                {/* {trip.cruise_id != 54 && ( */}
                                {hasIntermediateStops(trip) && (
                                  <>
                                    <div className="dot_vertical"></div>
                                    <div className="line_vertical"></div>
                                  </>
                                )}
                                
                                <div className="dot_vertical"></div>
                              </div>
                            </div>
                            
                            <div className="Timing_Paltform_vertical">
                              <div className="timingSlot_start">
                                <h3>{moment(trip.sailing_time, ["hh:mm:ss"]).format("hh:mm A")}</h3>
                                <p>{nameMap[trip.start_port]}</p>
                              </div>
                              
                              {trip.cruise_id != 54 ? (
                                // <div className="timingSlot_start">
                                //   <h3>{moment(trip.sailing_time, ["hh:mm:ss"]).format("hh:mm A")}</h3>
                                //   <p>{nameMap[trip.start_port]}</p>
                                // </div>
                                <p className="onehr-style">1hr Non Stop</p>
                              ) : (
                                <p className="onehr-style">1hr Non Stop</p>
                              )}
                              
                              <div className="timingSlot_start">
                                <h3>{moment(trip.sailing_date + " " + trip.sailing_time, "MM/DD/YYYY hh:mm:ss").add(1, "hours").format("hh:mm A")}</h3>
                                <p>{nameMap[trip.end_port]}</p>
                              </div>
                            </div>
                          </div>
                          {/* // New  Good Working Code End /// */}

                          <div className="M2MBookFlow_imgPra" key={index}>
                            <div className="M2MBookFlow_img_div1Pra">
                              <img src="/assets/img/FlowImg/car.png" alt="car"/>
                              {/* {vehicleAvailabilityArr1.map((addon, index2) =>
                                addon.accesstime == trip.sailing_date + " " + trip.sailing_time ? (
                                  <p key={index2}>{addon.carAvailabilityText}</p>
                                ) : null
                              )}  */}
                              <p>{trip.vehicleAvailabilityArr.cars.status}</p>
                            </div>
                            <div className="vertical_line_imgPra" key={index}></div>
                            <div className="M2MBookFlow_img_div1Pra">
                              <img src="/assets/img/Widget/Scooter2.0.png" alt="scooter"/>
                              {/* {vehicleAvailabilityArr1.map((addon, index2) =>
                                addon.accesstime ==
                                trip.sailing_date + " " + trip.sailing_time ? (
                                  <p key={index2}>{addon.bikeAvailabilityText}</p>
                                ) : null
                              )} */}
                              <p>{trip.vehicleAvailabilityArr.bikes.status}</p>
                            </div>{" "}
                          </div>
                          {/* {hasIntermediateStops(trip) ? ( */}
                          {trip.cruise_id != 54 ? (
                            <div key={`tripcruis${trip.cruise_id}${index}`} className="LAstNotePrincess">
                              <p >*wheelchair and pets not applicable</p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="trip-categories_princess">
                        {trip.availablity.sort().map((availablity, index1) => (
                          <>
                          {tripsData[availablity.category_id] ? (
                            <div key={`avail${index1}`} className={`${trip.availablity.length === 3 ? "M2MOne_Div" : "PrincessDiv"} boxFlow${index1 + 1} ${isExpanded !== index && !(availablity.available_room > 0 && !( moment(trip.sailing_date, 'MM/DD/YYYY').isBetween( tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date, undefined, "[]" ) && tripsData[availablity.category_id].status == 0 )) ? "unavailable" : ""}`}>
                            {availablity.available_room < 1 ? (
                              null
                            ):null}
                            <div after-dynamic-value={`${tripsData[availablity.category_id].disable_message_desktop}`}
                              className={`ExpandHiddenContentUlPra ${isExpanded === index ? `${availablity.available_room < 1 && !(moment(trip.sailing_date, 'MM/DD/YYYY').isBetween(tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date, undefined, "[]") && tripsData[availablity.category_id].status == 0) ? "soldOut" : !(availablity.available_room > 0 && !(moment(trip.sailing_date, 'MM/DD/YYYY').isBetween(tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date, undefined, "[]") && tripsData[availablity.category_id].status == 0)) ? "comingSoon" : ""}` : ""} ${tripsData[availablity.category_id].disable_message_desktop ? "trip-unavailable" : ""}`}
                            >
                                <div className="hading_btn_Flow1 no-opacity" onClick={() => {toggleExpand(index)}}>

                                    <h1 className="category-name">{tripsData[availablity.category_id].category_name}</h1>
                                    {trip.cruise_id === 54 ? (
                                      <>
                                        {availablity.available_room > 0 && !( moment(trip.sailing_date, 'MM/DD/YYYY').isBetween( tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date, undefined, "[]" ) && tripsData[availablity.category_id].status == 0 ) ? ( (availablity.available_room / availablity.total_room) * 100 >= tripsData[availablity.category_id].feeling_threshold ? (
                                          <div className="commanTagsClass bg_Green">
                                            <div className="CommanWhiteDot_Labels"></div>
                                            <p>Available</p>
                                          </div>
                                        ) : (availablity.available_room / availablity.total_room) * 100 < tripsData[availablity.category_id] .feeling_threshold ? (
                                          <div className="commanTagsClass bg_feelingFast">
                                            <div className="CommanWhiteDot_Labels"></div>
                                            <p>Filling fast</p>
                                          </div>
                                        ) : null
                                      ) : 
                                        availablity.available_room < 1 && !(moment(trip.sailing_date, 'MM/DD/YYYY').isBetween(tripsData[availablity.category_id].start_date,tripsData[availablity.category_id].end_date,undefined,"[]") && tripsData[availablity.category_id].status == 0) ? (
                                        <div className="commanTagsClass bg_SoldOut">
                                          <div className="CommanWhiteDot_Labels"></div>
                                          <p>Sold Out</p>
                                        </div>
                                      ) : (
                                        <div className="commanTagsClass bg_SoldOut">
                                          <div className="CommanWhiteDot_Labels"></div>
                                          <p>Unavailable</p>
                                        </div>
                                      )}
                                      </>
                                    ) : (
                                      <>
                                        {availablity.available_room > 0 && !( moment(trip.sailing_date, 'MM/DD/YYYY').isBetween( tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date, undefined, "[]" ) && tripsData[availablity.category_id].status == 0 ) ? (
                                          // Use same percentage threshold logic for all vessels
                                          (availablity.available_room) > tripsData[availablity.category_id].feeling_threshold ? (
                                            <div className="commanTagsClass bg_Green">
                                              <div className="CommanWhiteDot_Labels"></div>
                                              <p>Available</p>
                                            </div>
                                          ) : (
                                            <div className="commanTagsClass bg_feelingFast">
                                              <div className="CommanWhiteDot_Labels"></div>
                                              <p>Filling fast</p>
                                            </div>
                                          )
                                        ) : availablity.available_room < 1 ? (
                                          <div className="commanTagsClass bg_SoldOut">
                                            <div className="CommanWhiteDot_Labels"></div>
                                            <p>Sold Out</p>
                                          </div>
                                        ) : (
                                          <div className="commanTagsClass bg_SoldOut">
                                            <div className="CommanWhiteDot_Labels"></div>
                                            {/* <p>Unavailable : {availablity.available_room}</p> */}
                                            <p>Unavailable</p>
                                          </div>
                                        )}
                                      </>
                                    )}
                                    
                                </div>
                                   
                              {isExpanded === index && (
                                <div className="no-opacity" dangerouslySetInnerHTML={{__html:tripsData[availablity.category_id].description,}}/>
                              )}
                              {/* 2nd box will be there */}
                                {availablity.available_room > 0 && 
                                !(moment(trip.sailing_date, 'MM/DD/YYYY').isBetween(tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date,undefined,"[]") && tripsData[availablity.category_id].status == 0) ? (
                                    <div className="FlowPriceTagCheckboxPra no-opacity">
                                      <div className="Round_Box_PricePRa" onClick={() =>setTripCategory( availablity.category_id, trip.sailing_time, trip.sailing_date)}>
                                        <input type="radio" name="price_time" className="round_checkboxFlowa"
                                          checked={selectedcat.sailing_time ===trip.sailing_time && selectedcat.catid ===availablity.category_id}
                                          onChange={() =>setTripCategory( availablity.category_id, trip.sailing_time, trip.sailing_date)}
                                          value={`${availablity.category_id}_${trip.sailing_time}_${trip.sailing_date}`}
                                        />
                                        <h5>₹{(availablity.single_room_price).replace(".00","")}</h5>
                                      </div>
                                    </div>
                                  ) : (
                                    null  // Don't render anything when sold out or unavailable
                                )}
                                {/* 2nd box will be there  End*/}
                                
                            </div>
                          </div>
                        ):null}
                        </>
                          
                        ))}
                      </div>
                    </div>
                    ):null
                    }
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          )} 
        </div>

        <div className="OnwardReturnFlow_Mobile">
          <div>
             <CalenderLine journeyType="onward" />
          </div>
          {/* breadcum code start */}
          <div className="Breadcum_Line_mob">
            <div className="Breadcum_Flow_mob" style={{ overflowX: "auto" }}>
              <div className="Breadcum_Flow_Home_mob">
                <Link to={"/"} className="link-without-underline"><h1>M2M</h1></Link>
                <p> Home</p>
              </div>
              <div className="Breadcum_Flow_Logo_mob">&#62;</div>
              <div className="Breadcum_Flow_Departure_mob" id="ActivTab_Breadcum">
                <h1>Departure</h1>
                <p>
                  {storageData.onwardJourney && nameMap[   storageData.onwardJourney.selectedFromAbbreviation ]}{" "}To{" "}
                  {storageData.onwardJourney &&nameMap[storageData.onwardJourney.selectedToAbbreviation]}
                </p>
              </div>
            </div>

            <div className="custom_select">
              <div className={`select_header ${isOpen ? "open" : ""}`} onClick={toggleOptions}>
                <div className="select_button">
                  <p>{selectedOption}</p>
                  <span className={`select_button_Unicode ${   isOpen ? "rotate" : "" }`}>&#62;</span>
                </div>
              </div>
              <div className={`options ${isOpen ? "open" : ""}`}>
                {options.map((option, index2) => (
                  <div className="option" key={index2} onClick={() => handleOptionClick(option)}>
                    {option}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* breadcum code end */}

           {loading ? (
            <Lottie
              animationData={animationData}
              style={{ width: "100px", height: "100px", margin: "auto" }}
            />
          ) : ( 
            <div className="MobileReturnFlow_Continer">
              {trips.map((trip, index) => (
                <React.Fragment key={`mobtrip${index}`}>
                  <div className="Princess_Wrapper" key={index}>
                    <div className="m2m_Wrapper_Content">
                      <div className="m2m_Wrapper_Content_heading">
                        <h1>{trip.cruise_name}</h1>
                      </div>
                      <div className="m2m_Wrapper_Content_TimingSlot">
                        <div className="timeline_Return_Mob">
                          <div className="location_Return_Mob">
                            <h1>{moment(trip.sailing_time, ["hh:mm:ss"]).format("hh:mm A")}</h1>
                            <p>{nameMap[trip.start_port]}</p>
                          </div>
                          <div className="dashed-line_Return_Mob">
                            <div className="dashed-line-segment_Return_Mob"></div>
                            <div className="dashed-line-text_Return_Mob">
                              1Hr Non Stop
                            </div>
                            <div className="dashed-line-segment_Return_Mob"></div>
                          </div>
                          <div className="location_Return_Mob" id="location_Return_Mob_RightSide">
                            <h1>{moment(  trip.sailing_date + " " + trip.sailing_time, "MM/DD/YYYY hh:mm:ss") .add(1, "hours") .format("hh:mm A")}</h1>
                            <p>{nameMap[trip.end_port]}</p>
                          </div>
                        </div>
                      </div>
                      <div className="m2m_Wrapper_Content_Data">
                        <div className="m2m_Wrapper_Content_Data_div">
                          <img src="/assets/img/Widget/car.png" alt="car" />
                          {/* <p>Available</p> */}
                          <div>
                            {/* {vehicleAvailabilityArr1.map((addon, index2) =>
                              addon.accesstime == trip.sailing_date + " " + trip.sailing_time ? (
                                <p key={`car${index2}`}>{addon.carAvailabilityText}</p>
                              ) : null
                            )} */}
                            <p>{trip.vehicleAvailabilityArr.cars.status}</p>
                          </div>
                        </div>
                        <div className="vertical-line-Dep-Ret"></div>
                        <div className="m2m_Wrapper_Content_Data_div">
                          <img src="/assets/img/Widget/Scooter2.0.png" alt="bike" />
                          {/* <p>filling fast</p> */}
                          <div>
                            {/* {vehicleAvailabilityArr1.map((addon, index2) =>
                              addon.accesstime ==trip.sailing_date + " " + trip.sailing_time ? (
                                <p key={`bike${index2}`}>{addon.bikeAvailabilityText}</p>
                              ) : null
                            )} */}
                            <p>{trip.vehicleAvailabilityArr.bikes.status}</p>
                          </div>
                        </div>
                      </div>
                      {trip.cruise_id != 54 ? (
                      <div className="LAstNotePrincess">
                        <p>*wheelchair and pets not applicable</p>
                      </div>
                    ) : null}
                    </div>
                  </div>
                  {/* common Section start */}
                  {trip.availablity.sort().map((availablity, index1) => (
                    <>
                    {tripsData[availablity.category_id] ? (
                      <div key={`tripavail${index1}`} className={`${ trip.availablity.length === 3 ? "M2MOne_Div": "PrincessDiv"} ${!(availablity.available_room > 0 && !( moment(trip.sailing_date, 'MM/DD/YYYY').isBetween( tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date, undefined, "[]" ) && tripsData[availablity.category_id].status == 0 )) && expandedPrincessSections[index] !== index1 ? "unavailable" : ""}`}>
                          <div after-dynamic-value={`${tripsData[availablity.category_id].disable_message_desktop}`} 
                            className={`TIcket_Infomation_Section Line${index1 + 1}
                              ${expandedPrincessSections[index] === index1 ? 
                                `${availablity.available_room < 1 && !(moment(trip.sailing_date, 'MM/DD/YYYY').isBetween(tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date, undefined, "[]") && tripsData[availablity.category_id].status == 0) ? "soldOut" : 
                                !(availablity.available_room > 0 && !(moment(trip.sailing_date, 'MM/DD/YYYY').isBetween(tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date, undefined, "[]") && tripsData[availablity.category_id].status == 0)) ? "disabled comingSoon" : "itsavailable"}` : ""}
                              ${availablity.available_room < 1 && !(moment(trip.sailing_date, 'MM/DD/YYYY').isBetween(tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date, undefined, "[]") && tripsData[availablity.category_id].status == 0) ? "soldOut" : ""}`}
                            key={index1}
                          >

                          <div className="TIcket_Infomation_Section_one" onClick={() =>handlePrincessSectionClick(index, index1)}>
                            <div className="Section_one_Heading no-opacity">
                              <h1 className="category-name">{tripsData[availablity.category_id].category_name}</h1>
                              <div className="hading_btn_Flow1_btn2Speacial">
                                {availablity.available_room > 0 &&
                                !(moment(trip.sailing_date, 'MM/DD/YYYY').isBetween(tripsData[availablity.category_id].start_date,tripsData[availablity.category_id].end_date,undefined,"[]") &&tripsData[availablity.category_id].status == 0
                                ) ?((availablity.available_room /availablity.total_room) *100 >=tripsData[availablity.category_id].feeling_threshold ? (
                                    <div className="commanTagsClass bg_Green">
                                      <div className="CommanWhiteDot_Labels"></div>
                                      <p>Available</p>
                                    </div>
                                  ) : (availablity.available_room /availablity.total_room) *100 <tripsData[availablity.category_id].feeling_threshold ? (
                                    <div className="commanTagsClass bg_feelingFast">
                                      <div className="CommanWhiteDot_Labels"></div>
                                      <p>Filling fast</p>
                                    </div>
                                  ) : null
                                ) : /* </div> */
                                availablity.available_room < 1 &&
                                  !(moment(trip.sailing_date, 'MM/DD/YYYY').isBetween(tripsData[availablity.category_id].start_date,tripsData[availablity.category_id].end_date,undefined,"[]") &&tripsData[availablity.category_id].status == 0) ? (
                                  <div className="commanTagsClass bg_SoldOut">
                                    <div className="CommanWhiteDot_Labels"></div>
                                    <p>Sold Out</p>
                                  </div>
                                ) : (
                                  <div className="commanTagsClass bg_SoldOut">
                                    <div className="CommanWhiteDot_Labels"></div>
                                    <p>Unavailable</p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="Section_one_buttons no-opacity">
                              <div>
                                <h1>Starts From</h1>
                                <p>₹{availablity.single_room_price}</p>
                              </div>
                              <div className={`Section_one_buttons_logo ${expandedPrincessSections[index] === index1? "rotate": ""}`}>
                                <img src="/assets/img/Widget/Arrow_Down.png" alt="down arrow"/>
                              </div>
                            </div>
                          </div>
                          {expandedPrincessSections[index] === index1 && (
                            <>
                              <div className="ExpandHiddenContentUl_mob no-opacity " dangerouslySetInnerHTML={{__html:tripsData[availablity.category_id].description,}}/>
                              <div className="hidden-content">
                                {/* 2nd box will be there */}
                                {availablity.available_room > 0 && 
                                  !(moment(trip.sailing_date, 'MM/DD/YYYY').isBetween(tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date,undefined,"[]") && tripsData[availablity.category_id].status == 0) ? (
                                      <div className="FlowPriceTagCheckboxPra no-opacity">
                                          <div className="Round_Box_Price" onClick={() =>setTripCategory( availablity.category_id, trip.sailing_time, trip.sailing_date)}>
                                            <input type="radio" name="price_time" className="round_checkboxFlowa"
                                              checked={selectedcat.sailing_time ===trip.sailing_time &&selectedcat.catid ===availablity.category_id}
                                              onChange={() =>setTripCategory( availablity.category_id, trip.sailing_time, trip.sailing_date)}
                                              value={`${availablity.category_id}_${trip.sailing_time}_${trip.sailing_date}`}
                                              disabled={!(availablity.available_room > 0 && !( moment(trip.sailing_date, 'MM/DD/YYYY').isBetween( tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date, undefined, "[]" ) && tripsData[availablity.category_id].status == 0 ))}
                                          />
                                            <h5>₹{availablity.single_room_price}</h5>
                                          </div>
                                      </div>
                                  ) : (
                                      null  // Don't render anything when sold out or unavailable
                                )}
                                {/* <div className="FlowPriceTagCheckbox no-opacity">
                                  <div className="Round_Box_Price" onClick={() =>setTripCategory( availablity.category_id, trip.sailing_time, trip.sailing_date)}>
                                    <input type="radio" name="price_time" className="round_checkboxFlowa"
                                      checked={selectedcat.sailing_time ===trip.sailing_time &&selectedcat.catid ===availablity.category_id}
                                      onChange={() =>setTripCategory( availablity.category_id, trip.sailing_time, trip.sailing_date)}
                                      value={`${availablity.category_id}_${trip.sailing_time}_${trip.sailing_date}`}
                                      disabled={!(availablity.available_room > 0 && !( moment(trip.sailing_date, 'MM/DD/YYYY').isBetween( tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date, undefined, "[]" ) && tripsData[availablity.category_id].status == 0 ))}
                                  />
                                    <h5>₹{availablity.single_room_price}</h5>
                                  </div>
                                  {/* <div className="Round_Box_Price_p">
                                    <p>*Available in indoor and outdoor seating</p>
                                  </div> 
                                </div> */}
                                {/* 2nd box will be there  End*/}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    ):null}
                    </>
                  ))}
                  {/* ))} */}
                </React.Fragment>
              ))}
            </div>
           )} 
        </div>
      </div>
      
      <FlowWidget showOnlyMobile={true} showOnlyDesktop={true} currentPageConfirmtion={true} handleButtonClick={handleButtonClick} isButtonDisabled={isButtonDisabled}/>
       <Modal isOpen={isOpenBrowserAlerts}  contentLabel="Example Modal" className="custom-modal-alert">
           <p>{isBrowserAlerts}</p>
           <div className="ModalButton"><button onClick={closeBrowserModal}>Ok</button></div>
        </Modal> 
    </>
  );
};
export default DepartureFlow;
